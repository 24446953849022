.App {
  display: flex;
  justify-content: center;
  padding: 20px;
  font-family: 'Signika', sans-serif !important; 
}

.container {
  max-width: 800px;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #7ED1E1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: 'Signika', sans-serif;
  color: white;
}

.App-link {
  color: #7ED1E1;
}

.title {
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiToggleButtonGroup-root {
  width: 100%;
  margin-bottom: 20px;
  font-family: 'Signika', sans-serif;
}

.MuiToggleButton-root {
  text-transform: capitalize !important;
  flex: 1;
  font-family: 'Signika', sans-serif;
}

.MuiToggleButton-root.Mui-selected {
  background-color: #7ED1E1 !important;
  color: white !important;
  font-weight: 700 !important;
}

.MuiToggleButton-root.Mui-selected:hover {
  background-color: #6cbbc9 !important;
}

.MuiSlider-root {
  color: #7ED1E1 !important;
}

.MuiSlider-thumb {
  background-color: #7ED1E1 !important;
}

.MuiSlider-rail {
  background-color: rgba(126, 209, 225, 0.85) !important;
}

.MuiSlider-track {
  background-color: #7ED1E1 !important;
}

.MuiTypography-root {
  font-family: 'Signika', sans-serif !important;
}

.MuiTypography-h4 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  font-size: 24px !important;
}

.MuiTypography-h6 {
  margin-top: 20px !important;
  font-size: 18px !important;
}

.MuiTypography-p {
  font-size: 16px !important;
  font-style: italic !important;
  font-family: 'Signika', sans-serif !important;
}